import React from "react";
import { motion } from "framer-motion";
import {
	FaFacebook,
	FaTwitter,
	FaInstagram,
	FaLinkedinIn,
} from "react-icons/fa";

// Sample icons for the cards, replace these with your actual icons
import Icon1 from "./images/email.png";
import Icon2 from "./images/phone.png";
import Icon3 from "./images/location.png";
import Icon4 from "./images/social.png";

import vector from "../Partners/Vector.png";

const contactOptions = [
	{
		icon: Icon1,
		title: "Send us an email",
		description:
			"Use the SaySwitch email provided to reach out now and get a response in a few minutes.",
		cta: (
			<a
				href="mailto:hello@sayswitchgroup.com"
				className="text-green-400 hover:underline"
			>
				hello@sayswitchgroup.com
			</a>
		),
	},
	{
		icon: Icon2,
		title: "Call Us now",
		description:
			"Use the SaySwitch contact details provided to reach out now and get a response in a few minutes.",
		cta: (
			<span className="flex flex-col gap-1">
				<a href="tel:+2347066032484" className="text-green-400 hover:underline">
					+234 706 6032 484
				</a>
				{/* <a href="tel:+2347066032484" className="text-green-400 hover:underline">
					+234 706 6032 484
				</a> */}
			</span>
		),
	},
	{
		icon: Icon3,
		title: "Our Location",
		description:
			"954A, Akarigbere close, off Idejo street, Adeola Odeku, Victoria Island, Lagos, Nigeria.",
		cta: (
			<a
				href="https://www.google.com/maps?q=954A+Akarigbere+close,+off+Idejo+street,+Victoria+Island,+Lagos"
				target="_blank"
				rel="noopener noreferrer"
				className="text-green-400 hover:underline"
			>
				See on Map
			</a>
		),
	},
	{
		icon: Icon4,
		title: "Follow us on social media",
		description:
			"Use the SaySwitch social media handles to follow us and get new updates.",
		cta: (
			<div className="flex gap-4 text-green-400">
				<a
					href="https://rb.gy/ctq8ba"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Facebook"
					className="hover:text-blue-600"
				>
					<FaFacebook />
				</a>
				<a
					href="https://twitter.com/SaySwitch_NG"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Twitter"
					className="hover:text-blue-400"
				>
					<FaTwitter />
				</a>
				<a
					href="https://www.instagram.com/SaySwitch_ng"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Instagram"
					className="hover:text-pink-500"
				>
					<FaInstagram />
				</a>
				<a
					href="https://linkedin.com"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="LinkedIn"
					className="hover:text-blue-700"
				>
					<FaLinkedinIn />
				</a>
			</div>
		),
	},
];

const ContactCard = ({ icon, title, description, cta }) => (
	<motion.div
		whileHover={{ scale: 1.05 }}
		className="group flex flex-col p-[2vw] xl:p-[4vw] gap-[1vw] xl:gap-[2vw] md:gap-[6vw] sm:p-[6vw] bg-black 
         transition-transform duration-300 ease-in-out transform hover:scale-105
        rounded-3xl shadow-lg aspect-[650/563] max-w-[650px] md:max-w-[350px] justify-between overflow-scroll scrollbar-hide"
		style={{
			backgroundImage: `url(${vector})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
		}}
		data-aos="fade-up"
	>
		<div
			className="flex flex-col w-full items-start justify-center
        gap-[1vw] xl:gap-[2vw] md:gap-[6vw] h-full"
		>
			<motion.img
				src={icon}
				alt=""
				className="aspect-square max-w-[52px] xl:max-w-[40px] md:max-w-[32px] 
                         transition-transform duration-300 ease-in-out group-hover:scale-105"
			/>
			<h1
				data-aos="fade-right"
				className="font-normal text-white text-left 
            6xl:text-[48px] 5xl:text-[40px] 4xl:text-[40px] 3xl:text-[38px] 2xl:text-[36px] 1xl:text-[32px] xl:text-[32px] lg:text-[28px] md:text-[28px] sm:text-[24px] xs:text-[24px]
            text-ellipsis overflow-hidden
            "
			>
				{title}
			</h1>
		</div>
		<div className="flex flex-col w-full items-start justify-between h-full">
			<p
				data-aos="fade-right"
				className="text-left text-white font-extralight 
            6xl:text-[28px] 5xl:text-[26px] 4xl:text-[26px] 3xl:text-[24px] 2xl:text-[22px] 1xl:text-[22px] xl:text-[22px] lg:text-[20px] md:text-[20px] sm:text-[16px] xs:text-[14px]
            text-ellipsis overflow-hidden"
			>
				{description}
			</p>
			<p
				data-aos="fade-right"
				className="text-left text-green-400 font-light group-hover:underline
                6xl:text-[28px] 5xl:text-[26px] 4xl:text-[26px] 3xl:text-[24px] 2xl:text-[22px] 1xl:text-[22px] xl:text-[22px] lg:text-[20px] md:text-[20px] sm:text-[16px] xs:text-[14px]
text-ellipsis overflow-hidden"
			>
				{cta}
			</p>
		</div>
	</motion.div>
);

const ContactSection = () => {
	return (
		<section className="bg-white p-[6vw] md:p-[8vw] flex flex-col justify-center items-center">
			<div className="grid grid-cols-2 lg:grid-cols-1 gap-12 justify-items-center h-full">
				{contactOptions.map((option, index) => (
					<ContactCard
						key={index}
						icon={option.icon}
						title={option.title}
						description={option.description}
						cta={option.cta}
					/>
				))}
			</div>
		</section>
	);
};

export default ContactSection;
